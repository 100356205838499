import React from "react";
import {
  GTM_PHONE_FOOTER_STICKY_MOBILE,
} from "../config/gtm_classes";

import "../styles/call_cta.scss";
import CallButton from "./CallButton";

const CallCta = ({ isLanding = true }) => {
  const currentHour = new Date().getHours();
  const isClosed = currentHour >= 20 || currentHour < 9;

  return isClosed ? (
    <a
      href={isLanding ? "#cennik" : "/cennik"}
      title="Rejestracja online"
      className={`call_cta is_closed ${GTM_PHONE_FOOTER_STICKY_MOBILE}`}
    >
      <div className="icon-wrapper is_closed">
        <CallButton size="S" className="no-animation-icon is_closed" />
        <span className="is_closed">Infolinia nieczynna</span>
      </div>
      <div className="online-wrapper is_closed">
        <div>Rejestracja online</div>
      </div>
    </a>
  ) : (
    <a
      href="tel:+48781445685"
      title="+48781445685"
      className={`call_cta ${GTM_PHONE_FOOTER_STICKY_MOBILE}`}
    >
      <div className="icon-wrapper">
        <CallButton className="normal-icon" />
        <span>Zadzwoń teraz</span>
      </div>
      <div className="phone-number">+48 781 445 685</div>
    </a>
  );
};

export default CallCta;
